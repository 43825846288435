import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter17 from '../common/src/assets/image/charity/specter/kapitola_17_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Sedemnásta kapitola: Globalizácia a komunizmus | Ako duch komunizmu vládne nášmu svetu"
           description="Sedemnásta kapitola: Globalizácia a komunizmus"
           image={imageChapter17}/>
        <h1>Sedemnásta kapitola: Globalizácia a komunizmus</h1>
        <p>Vďaka moderným komunikačným prostriedkom, doprave, počítačom a digitálnym sieťam sa vzdialenosti skrátili a hranice, ktoré stáli v ceste po tisíce rokov, sú dnes zmazané. A tak sa zdá, že sa svet zmenšil. Vzájomné kontakty medzi jednotlivými štátmi dosahujú bezprecedentnú úroveň. Toto zosilňovanie globálnej spolupráce je prirodzeným výsledkom technologického vývoja, nárastu populácie, nárastu produkcie a migrácie. Inými slovami, takáto globalizácia je výsledkom prirodzeného historického vývoja.</p>
        <p>Existuje však ešte jeden druh globalizácie, a ten je výsledkom toho, ako komunistické ideológie využili prirodzený proces globalizácie na rozvrátenie ľudstva. Práve takejto forme globalizácie sa budeme v tejto kapitole venovať.</p>
        <p>Po období renesancie vstúpili ľudské dejiny do veku dramatických zmien. Priemyselná revolúcia, ktorá začala koncom 18. storočia, značne zvýšila produktivitu, čo viedlo k spoločenským zvratom a k hlbokým duchovným a&nbsp;filozofickým zmenám. S pokrokom technológie začali dominovať materialistické a ateistické myšlienky a stále väčší počet ľudí odmietal tradičné spôsoby a duchovnú vieru.</p>
        <p>Za týchto historických okolností urobil duch komunizmu z globalizácie mocný nástroj, ako odrezať ľudí od ich tradičných kultúr a viery. Globalizácia síce prináša príležitosti pre medzinárodnú spoluprácu a vzájomné pochopenie, avšak rozpad hraníc medzi národmi a ekonomikami umožnil duchovi komunizmu skombinovať tie najhoršie stránky ako komunistických, tak aj nekomunistických systémov, pričom sa usiluje o široké politické a kultúrne kroky, aby čo najviac rozšíril svoju agendu po celom svete. Tento proces uľahčuje globalizovaný ekonomický a finančný systém a&nbsp;kvôli tomu je pre jednotlivé komunity a národy o to ťažšie odolať náporu komunistického ducha.</p>
        <p>Táto kniha opakovane zdôrazňuje, že komunizmus nie je iba nejaká teória, ale zlovoľný duch, ktorý skutočne existuje a&nbsp;ktorého konečným cieľom je zničiť ľudstvo. Je schopný takmer akejkoľvek mutácie, ktorá mu pomôže prežiť a expandovať. Od 90. rokov sa o globalizácii tvrdí, že má šíriť demokraciu, slobodný trh a&nbsp;trhovú ekonomiku, a rad ľavicových skupín sa teda voči nej vyhradzuje. Ale títo ľudia si neuvedomujú, že duch komunizmu pôsobí na vyššej úrovni. Cieľom komunizmu nie je použiť globalizáciu na vytvorenie lepšieho sveta, ale chopiť sa vlády nad svetom tým, že na všetky národy zemegule uvalí ideológiu globalistickej kontroly.</p>
        <p>Globalizácii sa podarilo urobiť značný pokrok hneď v&nbsp;niekoľkých oblastiach, najmä na poli ekonomiky, politiky a kultúry. Ako ideologická sila má globalizmus mnoho tvárí a prejavuje sa v&nbsp;celej škále rozličných podôb, dokonca aj v takých, ktoré sú na povrchu protichodné. Často vyvoláva nejasné predstavy o svete bez vojen, chudoby, diskriminácie a vykorisťovania, no v praxi sú metódy, ktoré na dosiahnutie týchto ideálov navrhuje, veľmi podobné utopickým klamstvám komunistickej revolúcie.</p>
        <p>Hoci má každý národ svoju vlastnú kultúru a históriu, ich rozmanité tradície obsahujú univerzálne morálne hodnoty, ktoré sú spoločné pre celé ľudstvo. Národná suverenita a kultúrne tradície každej etnickej skupiny hrajú dôležitú úlohu v sebaurčení národa a&nbsp;jeho dedičstva a ponúkajú kolektívnu ochranu pred najrôznejšími hrozbami, od prírodných katastrof až po vojenské invázie. Okrem toho národné povesti a náboženská viera danej národnostnej skupiny pomáhajú ľuďom udržiavať si pocit identity a chránia ich, aby sa nenechali oklamať zlovoľnými úkladmi ducha komunizmu.</p>
        <p>Globalisti často tvrdia, že zastupujú kultúry všetkých národností, ale za posledné roky je čím ďalej zjavnejšie, že táto ideológia slúži k posilneniu ľavicových myšlienok. Miesto toho, aby podporovali tradičnú kultúru, ktorá je zakorenená vo viere a hodnotách, globalisti používajú slovník odrážajúci ľavicové pojmy ako „politická korektnosť“, „sociálna spravodlivosť“, „hodnotová neutralita“ a „absolútne rovnostárstvo“.</p>
        <p>Hlavným cieľom globalizácie je svetovláda a&nbsp;tá začína od&nbsp;nadnárodných inštitúcií a smerníc. Akonáhle dôjde k utvoreniu globálnej svetovlády, komunizmus ľahko dosiahne svoj cieľ – zrušiť súkromný majetok, suverénne štáty, rôzne rasy a tradičnú kultúru jednotlivých národov.</p>
        <h2>1. Globalizácia a&nbsp;komunizmus</h2>
        <p>Karol Marx vo svojich prácach nehovoril priamo o globalizácii, ale používal výraz „svetové dejiny“, ktorý má podobné konotácie. V <em>Komunistickom manifeste</em> Marx a spoluautor Friedrich Engels tvrdia, že globálne šírenie kapitalizmu nevyhnutne vytvorí obrovský proletariát a proletárska revolúcia potom zachváti celú zemeguľu, zvrhne kapitalizmus a dosiahne „raj“ komunizmu. Marx a Engels tiež napísali: „Proletariát teda môže existovať iba v [prostredí] svetovo-dejinnom, rovnako ako komunizmus, jeho činnosť môže mať iba ,svetovo-dejinnú‘ existenciu.“<a href="#_edn1" id="_ednref1">[1]</a> To znamená, že zavedenie komunizmu je podmienené tým, aby proletariát po celom svete podnikol spoločné kroky. Komunistická revolúcia musí byť globálne hnutie.</p>
        <p>Lenin neskôr Marxovu doktrínu pozmenil a navrhol, aby sa revolúcia odštartovala najskôr v Rusku, napriek prevažne vidieckemu charakteru vtedajšej ruskej spoločnosti. Sovietski komunisti založili v roku 1919 v Moskve Komunistickú internacionálu, ktorej pobočky vyrástli vo viac ako 60 krajinách sveta. Lenin sám vyhlasoval, že cieľom Komunistickej internacionály je založenie Svetovej sovietskej republiky.<a href="#_edn2" id="_ednref2">[2]</a></p>
        <p>Sovietsky vodca Josif Stalin, ktorý nastúpil po Leninovi, bol známy dočasnou politikou „socializmu v jednej krajine“, ale vo svojej knihe <em>Marxizmus a národná otázka</em> navrhuje niekoľko cieľov komunistickej globálnej revolúcie. Americký mysliteľ G. Edward Griffin zhrnul Stalinove návrhy nasledovne:</p>
        <p><em>1. Zmiasť, dezorganizovať a zničiť sily kapitalizmu po celom svete.</em></p>
        <p><em>2. Zlúčiť všetky národy do jediného svetového ekonomického systému.</em></p>
        <p><em>3. Prinútiť vyspelé krajiny, aby dlhodobo posielali finančnú pomoc rozvojovým krajinám.</em></p>
        <p><em>4. Rozdeliť svet na regionálne zoskupenia v rámci prechodnej fázy k úplnej svetovláde. Obyvateľstvo bude ochotnejšie vzdať sa vernosti k národu kvôli lojalite k vágne vymedzenému regiónu než kvôli svetovej nadvláde. Regionálne zoskupenia [napr. dnešné NATO, SEATO a pod.] môžu byť neskôr zlúčené do jedinej svetovej diktatúry proletariátu.<a href="#_edn3" id="_ednref3">[3]</a></em></p>
        <p>Bývalý predseda Komunistickej strany USA, William Z. Foster, napísal: „Komunistický svet bude zjednoteným, organizovaným svetom. Ekonomický systém bude jedna veľká organizácia založená na princípe plánovania, ktoré sa teraz zavádza v ZSSR. Americká sovietska vláda bude dôležitou sekciou tejto svetovlády.“<a href="#_edn4" id="_ednref4">[4]</a></p>
        <p>Od výrokov Marxa, Lenina, Stalina a Fostera až po ideu „spoločenstvo ľudského osudu“, ktorú navrhuje ČKS, možno jasne vidieť, že komunizmus sa neuspokojí s mocou len v niekoľkých krajinách. Ideológia komunizmu vo všetkých svojich podobách zahŕňa ambíciu podrobiť si celé ľudstvo.</p>
        <p>Svetová revolúcia proletariátu, ktorú Marx predvídal, sa neuskutočnila. To, čo on sám považoval za zúfalé a&nbsp;upadajúce kapitalistické spoločnosti, sa nakoniec ukázalo ako víťazný, prosperujúci a prekvitajúci systém so súkromným vlastníctvom a právnym štátom. S rozpadom Sovietskeho zväzu a&nbsp;komunistického tábora vo východnej Európe a so zavedením trhových princípov režimu ČKS sa zdalo, že slobodný svet nad komunizmom vyhral. Lenže duch komunizmu sa skrýva za rôzne doktríny a hnutia, a&nbsp;pritom rozkladá, infiltruje a šíri komunistické prvky do každého kúta slobodného sveta. Socializmus, ktorý je prvotnou fázou komunizmu, opäť získava na obľube a&nbsp;priživuje sa na destabilizačných prvkoch, ktoré priniesla globalizácia a globalistické faktory.</p>
        <p>Ľavicové prúdy v Európe po druhej svetovej vojne ďalej silneli. Socialistická internacionála, ktorá presadzovala demokratický socializmus, zahŕňala politické strany z viac ako stovky krajín. Tieto strany sa v rôznych krajinách dostali k moci a dokonca sa rozšírili vo väčšine Európy, kde presadili štedrý systém sociálneho&nbsp; zabezpečenia, vysoké dane a väčšiu mieru štátneho vlastníctva.</p>
        <p>Globalizácia vyprázdnila americký priemysel, zredukovala strednú triedu, spôsobila stagnáciu príjmov, polarizuje bohatých a&nbsp;chudobných a vyvoláva v spoločnosti rozpory. V Spojených štátoch to značne posilnilo rast ľavice a socializmu a za posledných asi desať rokov to posunulo globálne politické&nbsp; kormidlo ostro doľava. Ľavicové sily vo svete poukazujú na to, že globalizácia spôsobila nerovnosť v príjmoch a polarizáciu medzi chudobnými a bohatými. Spoločne s&nbsp;týmito argumentmi rapídne stúpajú protiglobalizačné nálady a stávajú sa novou silou, ktorá oponuje kapitalizmu a volá po socializme.</p>
        <p>Po studenej vojne sa komunistické myšlienky infiltrovali do ekonomickej globalizácie s cieľom odstrániť národné ekonomiky a&nbsp;podkopať ekonomické základy každej krajiny. Ľudská chamtivosť, predtým obmedzená na komunity, bola internacionalizovaná a&nbsp;stala sa mocnou globálnou silou. Západné finančné mocnosti presunuli v posledných desaťročiach bohatstvo nazhromaždené počas niekoľkých sto rokov tamojšou spoločnosťou do Číny. Potom, čo ČKS uskutočnila trhové reformy, poslúžilo toto bohatstvo na urýchlenie rastu čínskej ekonomiky.</p>
        <p>Ako hlava komunistických síl v súčasnom svete sa ČKS zameriava na vybudovanie socialistickej ekonomickej superveľmoci, pričom zároveň vlieva sily do ľavicových a&nbsp;komunistických strán vo svete. Jej totalitný režim rozvrátil pravidlá normálneho svetového obchodu a nadobudnuté bohatstvo získané od demokratických slobodných trhov používa na ich rozvracanie zvnútra. Ekonomická sila čínskych komunistov podnietila ich politické a vojenské ambície. Cieľom je opäť exportovať autoritársky komunistický model do sveta. Keď sa na globalizačnú stratégiu čínskeho režimu pozrieme z perspektívy Marxa, Lenina a Stalina, uvedomíme si, že dnešný svet spĺňa mnohé z podmienok nutných k uskutočneniu komunistickej revolúcie.</p>
        <h2>2. Ekonomická globalizácia&nbsp;</h2>
        <p>Ekonomickou globalizáciou sa označuje integrácia reťazcov globálneho kapitálu, výroby a obchodu, ktorá začala v 40. a 50. rokoch minulého storočia, zrela v 70. a 80. rokoch, aby sa v 90. rokoch stala ustanovenou normou. Hnacou silou boli medzinárodné organizácie a nadnárodné korporácie, ktoré požadovali uvoľnenie regulácie a kontroly, aby sa umožnil voľný tok kapitálu.</p>
        <p>Na povrchu bola ekonomická globalizácia pretláčaná západnými štátmi na šírenie kapitalizmu do zvyšku sveta. Nanešťastie sa globalizácia časom stala účelovým prostriedkom na šírenie komunizmu. Konkrétne globalizácia spôsobila, že západné štáty poskytli čínskemu režimu finančnú pomoc, čo viedlo k vzájomnej závislosti medzi kapitalistickou trhovou ekonomikou a socialistickou totalitnou ekonomikou ČKS. Západ obetoval svoje svedomie a univerzálne hodnoty výmenou za ekonomické výhody, zatiaľ čo komunistický režim v Číne si rozširuje sféru vplyvu pomocou ekonomického nátlaku.</p>
        <p>&nbsp;</p>
        <h3>a)&nbsp; Destabilizujúce účinky globalizácie</h3>
        <p>V procese globalizácie vznikli veľké medzinárodné organizácie, uzavreli sa medzinárodné zmluvy a nariadenia, najmä globalizácia národných ekonomík. Na povrchu to môže pôsobiť dojmom, že ide o expanziu kapitalizmu a voľného trhu, ale v skutočnosti sa utvoril unifikovaný ekonomický systém. Systém, ktorý môže vydávať nariadenia rozhodujúce o osude firiem v mnohých krajinách naraz. Po založení tohto medzinárodného finančného poriadku vznikol tiež fenomén dlhoročnej ekonomickej pomoci rozvojovým krajinám, presne v&nbsp;duchu tretieho Stalinovho návrhu popísaného vyššie.</p>
        <p>Čo sa týka finančnej pomoci, medzinárodné finančné organizácie ako napr. Svetová banka zvyčajne zavádzajú v ekonomike štátu, ktorému sa pomáha, makroekonomický intervencionizmus, čo je nielen autoritárska praktika, ale zároveň podkopáva voľný trh. Takisto ignoruje sociálne, kultúrne i historické podmienky danej krajiny. Vo výsledku to prináša menej slobody a väčšiu centralizáciu ekonomiky. Americký akademik James Bovard uvádza, že Svetová banka „silne presadzovala znárodnenie ekonomík tretieho sveta a zvýšila politickú a&nbsp;byrokratickú kontrolu nad životmi tých najchudobnejších ľudí“.<a href="#_edn5" id="_ednref5">[5]</a></p>
        <p>Ekonomická globalizácia tiež viedla k utvoreniu homogénnej globálnej ekonomiky, kde panujú podobné spotrebiteľské trendy a unifikované mechanizmy výroby a spotreby. Drobné firmy a podnikatelia napojení na národnostné menšiny boli vlnou globalizácie jednoducho zmätení. Mnoho ľudí prišlo o prostredie, kde by sa mohli v podnikaní vnútri hraníc vlastnej krajiny alebo oblasti slobodne realizovať. Ako sa svet stáva s rozvojom komunikačnej a dopravnej technológie čím ďalej tým viac prepojený, mohlo by sa zdať, že globalizácia prinesie celej zemeguli&nbsp; finančnú prosperitu a demokratické hodnoty, avšak v mnohých prípadoch je opak pravdou.</p>
        <p>Rozvojové krajiny sa stávajú súčasťou globálneho výrobného reťazca, čo oslabuje ekonomickú suverenitu jednotlivých národov a v niektorých prípadoch to vedie k štátnemu kolapsu. Niektoré krajiny sú silne zaťažené dlhmi, ako aj nutnosťou splácať ich, a to od základu narúša základy slobodných kapitalistických ekonomík.</p>
        <p>Globalizácia oslabuje rozvojové krajiny tiež inými spôsobmi. Jamajka otvorila krátko po roku 2000 svoj trh a začala vo veľkom dovážať lacné kravské mlieko. Vďaka tomu sa mlieko síce stalo ďaleko dostupnejšou komoditou, ale tiež to priviedlo miestnych chovateľov dojných kráv k bankrotu. Nedokázali obstáť pred záplavou lacného dovozu zo zahraničia. V Mexiku býval celý rad tovární ľahkého priemyslu, ale po vstupe Pekingu do Svetovej obchodnej organizácie bola väčšina pracovných miest zrušená a premiestnená do Číny. Mexiko tým ťažko utrpelo, pretože nedokáže súperiť s&nbsp;nízkymi pracovnými nákladmi a&nbsp;obrovskou kapacitou Číny. Počas ekonomickej krízy v Ázii v roku 1997 otvorilo Thajsko svoj oslabený finančný systém medzinárodným investíciám. Prinieslo to síce dočasnú prosperitu, avšak keď zahraniční investori odišli, thajská ekonomika sa zastavila a to negatívne ovplyvnilo aj susedné krajiny.</p>
        <p>Rad rozvojových krajín oplýva nerastným bohatstvom, no hoci do nich boli naliate bohaté zahraničné investície, pre miestnych obyvateľov to malo len veľmi malý ekonomický prínos. Globalizácia tvrdí, že prináša rozvojovým krajinám demokraciu, ale v skutočnosti posilňuje skorumpovaných úradníkov, zatiaľ čo verejnosť naďalej živorí.</p>
        <p>Ako poznamenáva profesor Dani Rodrik z Harvardskej univerzity, máme tu „trilému“ globalizácie: „Nemôžeme sa zároveň usilovať o demokraciu, právo národov na sebaurčenie a ekonomickú globalizáciu.“<a href="#_edn6" id="_ednref6">[6]</a> V tom spočíva skrytá vada globalizácie, ktorú komunizmus zneužil.</p>
        <p>Z výhod a príležitostí, ktoré globalizácia prináša, čerpá často len elitná hŕstka obyvateľstva. V mnohých krajinách globalizácia zhoršila nerovnosť a nedokáže ponúknuť dlhodobé riešenia.</p>
        <h3>b) Ako globalizácia uľahčuje šírenie komunistickej ideológie</h3>
        <p>Komunistická ideológia rovnostárstva a étos boja sa po svete šíri spolu s globalizáciou. Globalizácia nahlodáva národnú suverenitu, zhoršuje regionálne problémy a plodí typický marxistický konflikt medzi „utláčateľmi“ a „utláčanými“, ktorý ľavicovo zmýšľajúci ľudia používajú ako ideologickú zbraň v boji proti voľnému trhu, súkromnému vlastníctvu a ďalším aspektom „starej spoločnosti“.</p>
        <p>Polarizácia bohatstva vytvorená globalizáciou vytvára začarovaný kruh. Ohromný odliv pracovných miest, ako aj celých odvetví, urobil z nižšej a strednej triedy v západných krajinách obete globalizácie. Zoberme si napríklad USA. S masívnym odlivom kapitálu a technológií do Číny, zaniklo veľa pracovných miest vo výrobe. Výsledkom bol úpadok alebo strata celých priemyselných odvetví, miliónov pracovných miest a nárast nezamestnanosti. V rokoch 2000 až 2011 bolo zatvorených 65 tisíc tovární a o prácu vo výrobných odvetviach prišlo 5,7 milióna ľudí, čo zodpovedá 33&nbsp;% z&nbsp;ich celkového počtu.<a href="#_edn7" id="_ednref7">[7]</a></p>
        <p>Rozdiely medzi chudobnými a bohatými v Spojených štátoch dlhodobo narastajú. Za posledných tridsať rokov sa spomalil nárast priemernej mzdy (po zapracovaní inflácie), čo vedie k vzniku pracujúcich chudobných, t. j. tých, ktorí pracujú alebo si hľadajú prácu 27 týždňov v roku, ale ktorých príjem je aj tak pod oficiálnou hranicou chudoby. V roku 2016 sa medzi pracujúcich chudobných v USA počítalo 7,6 milióna obyvateľov.<a href="#_edn8" id="_ednref8">[8]</a></p>
        <p>Polarizácia chudobných a bohatých poskytuje úrodnú pôdu pre rast komunistickej ideológie. Ekonomické problémy sa nikdy neobmedzujú len na ekonomickú oblasť, naopak, ďalej sa rozrastajú. Vyžadovanie „sociálnej spravodlivosti“ a riešenie neférového rozdelenia príjmov viedlo k prívalu socialistickej ideológie a s tým vzrástli tiež požiadavky na sociálne zabezpečenie, čo vytvára viac chudobných rodín. Vzniká tak začarovaný kruh klesajúcej produktivity a&nbsp;zvyšujúcej sa závislosti na štáte.</p>
        <p>V USA sa politické spektrum po roku 2000 čoraz viac otvára ľavicovým vplyvom, a tak sme mohli byť pri prezidentských voľbách roku 2016 svedkami stúpajúceho počtu hlasov volajúcich po socializme a nárastu politickej polarizácie. Za týmito zmenami leží vo veľkej miere dopad globalizácie. S čím väčšími problémami sa demokratická spoločnosť na Západe stretáva, tým triumfálnejšie sa sily komunizmu zdajú byť na svetovej scéne.</p>
        <p>Komunistický duch hrá bez výčitiek svedomia na oboch stranách ihriska, len aby dosiahol svoje ciele. Preto spolu s rozvojom globalizácie prišli antiglobalizačné kampane, ktorých začiatok ohlásili násilné protesty v Seattli v novembri 1999, kedy davy demonštrantov protestovali proti konferencii Svetovej obchodnej organizácie (WTO). Podobné obrie demonštrácie sa vyskytli aj v roku 2001 na troch veľkých medzinárodných konferenciách (Americkom summite v&nbsp;kanadskom Quebecu, summite Európskej únie vo švédskom Göteborgu a ekonomickom summite G-8 v talianskom Janove).</p>
        <p>Celosvetové antiglobalizačné kampane priťahujú účastníkov všemožného razenia. Drvivá väčšina z nich sú otvorení ľavicoví oponenti kapitalizmu, vrátane odborov a environmentálnych organizácií, ako aj obete globalizácie a&nbsp;znevýhodnení ľudia. Výsledkom je, že verejnosť, či už ide o zástancov alebo oponentov globalizácie, neúmyselne slúži cieľom komunizmu.</p>
        <h3>c) Západný kapitalizmus: podpora ČKS</h3>
        <p>Keď sa hovorí o úspechoch globalizácie, akademici často uvádzajú ako príklad Čínu, ktorá sa vďaka nej stala druhou najväčšou ekonomikou na svete. Mnohí ľudia predpovedali, že Čína nakoniec predbehne aj Spojené štáty.</p>
        <p>Na rozdiel od mexického modelu lacného zdroja výroby sa ČKS snaží dostať k tým najmodernejším technológiám zo Západu a potom svoju konkurenciu nahradiť. Výmenou za vstup na čínsky trh strana vyžaduje, aby zahraničné firmy z&nbsp;vyspelých krajín založili v Číne joint venture a z nich následne doluje kľúčové technológie. K tomu čínsky režim používa rad metód, od prinútenia k prevodu technológií až po otvorenú krádež prostredníctvom hackingu. Keď sa režimu podarilo lacno získať vyspelé technológie, využil túto výhodu na&nbsp;zaplavenie svetového trhu svojimi produktmi, ktoré sa predávajú za dumpingové ceny. Prostredníctvom daňových úľav a štátnej pomoci sa čínskym podnikom podarilo poraziť svoju konkurenciu podhodnotenými cenami a narušiť tak fungovanie voľného trhu.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p>Na rozdiel od väčšiny rozvojových krajín, ktoré svoje trhy otvorili, ČKS vytvorila na domácom trhu rad obchodných prekážok. Po vstupe do Svetovej obchodnej organizácie v&nbsp;roku 2001 zneužila Čína medzery v pravidlách voľného obchodu a&nbsp;zároveň zneužila globalizáciu k zaplaveniu svetových trhov čínskym tovarom. Zároveň si strana ponechala kontrolu nad kľúčovými odvetviami, ako sú telekomunikácie, bankovníctvo a&nbsp;energetický priemysel. Tak sa pevninskej Číne podarilo využiť výhody globálnej ekonomiky, bez toho aby sa obťažovala dodržiavať svoje záväzky.</p>
        <p>Západný svet oslepený ekonomickými ziskami ignoroval porušovanie ľudských práv komunistickou stranou a ďalej režim veľkoryso podporoval. Za pomoci globalizácie zasadili mocná ČKS spoločne s morálne skazenou čínskou spoločnosťou tvrdý úder trhovej ekonomike a obchodným predpisom na Západe.</p>
        <p>Tým, že ČĽR ignorovala pravidlá obchodu, zožala všetky výhody globalizácie. V určitom zmysle pre ňu globalizácia predstavovala niečo ako krvnú infúziu, vďaka ktorej sa upadajúci komunistický štát prebral späť k životu. Pomocou manipulácie globalizácie sa dosiahol skrytý cieľ prerozdelenia bohatstva, čo pomohlo udržať čínsky režim.</p>
        <p>Globalizácia sa tak stala procesom vedúcim k záchrane komunistického režimu v Číne i jeho legitimizovaniu. Zatiaľ čo komunistická strana posilnila svoje socialistické svaly kapitalistickými bielkovinami, Západ sa dostal do relatívneho úpadku, čo komunistom v&nbsp;Číne dodalo dôveru v totalitarizmus a vlastné globálne ambície. Vzostup Číny takisto veľmi povzbudil zástupcov socialistického a&nbsp;ľavicového tábora po celom svete.</p>
        <p>S rastom čínskej ekonomiky zintenzívnila komunistická strana snahy o&nbsp;infiltráciu medzinárodných ekonomických zoskupení ako Svetová obchodná organizácia, Medzinárodný menový fond, Svetová banka, Organizácia OSN pre priemyselný rozvoj a ďalšie. Keď sa stranícki funkcionári dostali na dôležité pozície v týchto inštitúciách, priviedli ich k tomu, aby s komunistickou stranou spolupracovali a podporili tak jej plány a stratégiu.</p>
        <p>ČKS využíva medzinárodné ekonomické organizácie na dosiahnutie svojich vlastných cieľov a korporativistického modelu. Ak jej ambície nebudú zastavené, nie je pochýb o tom, že režim prinesie pohromu ako globálnej ekonomike, tak globálnej politike.</p>
        <h2>3. Politická globalizácia</h2>
        <p>Globalizácia sa politicky prejavuje ako zvýšená spolupráca medzi krajinami, vznik medzinárodných organizácií, formulácia politických cieľov a medzinárodných dohovorov. Po objavení sa medzinárodných inštitúcií ako aj pravidiel a smerníc presahujúcich hranice národa sa z týchto organizácií stali základne medzinárodnej moci, ktoré oslabujú národnú suverenitu a zasahujú do kultúrneho, sociálneho i morálneho života v jednotlivých štátoch. V mene podpory svetového mieru a medzinárodného porozumenia sa globalistické inštitúcie zameriavajú na upevnenie moci, aby postupne zaviedli komunistický program.</p>
        <p>Komunistický duch v tomto procese využíva medzinárodné organizácie, aby posilnil komunistické faktory, presadzoval Marxistickú filozofiu boja a propagoval pokrivené definície ľudských práv a slobôd. Globalizmus presadzuje socialistické myšlienky v celosvetovom meradle, napríklad prerozdeľovanie bohatstva alebo vytvorenie svetovlády, ktorá zavedie ľudstvo na cestu totality.</p>
        <h3>a) Expanzia politickej moci komunizmu prostredníctvom OSN</h3>
        <p>Organizácia Spojených národov (OSN) založená po druhej svetovej vojne je najväčšou medzinárodnou organizáciou na svete a pôvodne mala posilňovať spoluprácu a koordináciu medzi krajinami. Ako nadnárodný subjekt bola OSN využitá komunistickými silami k&nbsp;oslabeniu a zničeniu národných štátov. OSN sa už od svojho vzniku stala nástrojom Sovietmi vedeného komunistického tábora a&nbsp;pre komunistickú stranu slúži ako platforma na propagáciu komunistickej ideológie svetovlády.</p>
        <p>Keď bola založená OSN a&nbsp;zostavovala sa Charta OSN, jedným zo sponzorských krajín bol Sovietsky zväz, ktorý bol stálym členom Bezpečnostnej rady a hral teda rozhodujúcu úlohu. Alger Hiss, navrhovateľ charty a generálny tajomník Konferencie OSN o&nbsp;medzinárodnej organizácii v&nbsp;roku 1945, ktorý zároveň pôsobil na americkom ministerstve zahraničia a ako dôležitý poradca prezidenta Roosevelta, bol v roku 1950 uznaný vinným z krivej prísahy v&nbsp;spojitosti s obvinením zo špionáže v prospech Sovietskeho zväzu. Skryté slabé miesta v Charte OSN a v medzinárodných dohovoroch nahrávajú komunistickým režimom a pravdepodobne sa o&nbsp;to do veľkej miery pričinil práve Hiss. Tieto konvencie boli použité na priame či nepriame propagovanie komunistických myšlienok a&nbsp;rozširovanie komunistickej moci.</p>
        <p>Vedúci pracovníci mnohých dôležitých úradov v OSN sú komunisti alebo ich sympatizanti. Mnoho generálnych tajomníkov OSN bolo a je socialistického či marxistického zmýšľania. Napríklad prvý generálny tajomník OSN, Trygve Lie, bol nórsky socialista, ktorému sa dostalo silnej podpory zo strany Sovietskeho zväzu. Jeho najdôležitejšou úlohou bolo priviesť do Organizácie Spojených národov Čínsku ľudovú republiku.</p>
        <p>Jeho nasledovník, Dag Hammarskjöld, bol socialista a sympatizant celosvetovej komunistickej revolúcie, ktorý často lichotil vysokému funkcionárovi ČKS, Čou En-lajovi. Tretí generálny tajomník, U Thant z Barmy (Mjanmarska), bol marxistom, ktorý veril, že Leninove ideály sa zhodujú s Chartou OSN. Šiesty generálny tajomník, Butrus Butrus-Ghali z Egypta začal svoju politickú kariéru v režime Gamála Násira a býval kedysi podpredsedom socialistickej internacionály.<a href="#_edn9" id="_ednref9">[9]</a> Nie je teda ťažké pochopiť, prečo sa predstaviteľom komunistických režimov pravidelne dostáva v OSN najvyšších pôct.</p>
        <p>Najdôležitejším poslaním OSN je udržiavať vo svete mier a bezpečnosť. Mierové jednotky OSN spadajú do kompetencie zástupcu generálneho tajomníka pre politické a bezpečnostné otázky, pričom v rokoch 1946 až 1992 túto funkciu zastávali v&nbsp;trinástich prípadoch zo štrnástich sovietski občania. Sovietsky komunistický režim sa nikdy nevzdal snáh rozšíriť komunistickú moc a&nbsp;nikdy nemal záujem na svetovom mieri. Hoci používal slogan „ochraňovať svetový mier“, jeho skutočným cieľom bolo použiť OSN ako prosocialistickú organizáciu na presadzovanie komunistického hnutia. Komunistický vplyv bol v OSN tak silný, že sa organizácia správala doslova ako bábka Sovietskeho zväzu. Vtedajší riaditeľ FBI, J. Edgar Hoover, uviedol v roku 1963, že komunistickí diplomati pri OSN „predstavujú jadro ruských výzvedných operácií v tejto krajine“.<a href="#_edn10" id="_ednref10">[10]</a></p>
        <p>Dokonca aj po rozpade bývalého Sovietskeho zväzu zostáva komunistické dedičstvo v OSN naďalej rozšírené. Denník <em>The Wall Street Journal</em> napísal v roku 1991: „Mnohí z tých, ktorí pracujú na sekretariáte alebo na misiách v jeho blízkosti, tvrdia, že komunizmus zanechal na byrokracii OSN svoje stopy. ... ,Je to ako osteň škorpióna,’ vyhlásil jeden pracovník OSN. ,Škorpión, t. j. socializmus východného bloku, umiera, ale osteň zostáva jedovatý a útočí na nové obete.’... [Po mnoho rokov,] sa ľudia zo Západu, ktorí pracovali v&nbsp;OSN, ocitli v obklopení toho, čo mnohí nazývali komunistickou mafiou.“<a href="#_edn11" id="_ednref11">[11]</a></p>
        <p>ČKS zneužíva OSN ako propagandistickú platformu. Napríklad najvyšší predstavitelia OSN, vrátane generálneho tajomníka, podporujú čínsky projekt Novej Hodvábnej cesty, globálnej investičnej iniciatívy pre rozvoj infraštruktúry, ako nástroj na boj s chudobou v rozvojovom svete. V&nbsp;skutočnosti ide o spôsob, akým si ČKS rozširuje svoju medzinárodnú hegemóniu. Nová Hodvábna cesta priviedla mnoho krajín do dlhovej krízy. Napríklad Pakistan musel kvôli zadlženosti voči Číne požiadať Medzinárodný menový fond o finančnú pomoc vo výške šiestich miliárd amerických dolárov. Ďalší sa museli vzdať kontroly nad kritickou infraštruktúrou v prospech Číny. Srí Lanka musela čínskemu režimu na 99 rokov prenajať dôležitý prístav Hambantota ako splátku za svoje dlhy. Okrem toho iniciatíva umožňuje čínskemu režimu uplatniť kontrolu nad politikou a ekonómiou účastníckych krajín, pričom podkopáva ľudské práva a demokraciu v týchto štátoch.<a href="#_edn12" id="_ednref12">[12]</a></p>
        <h3>b) Podkopávanie ľudskoprávnych ideálov OSN</h3>
        <p>Jedným z cieľov OSN je zlepšovať ľudské práva a&nbsp;presadzovať slobodu. Ide o univerzálny princíp. Avšak ČKS spoločne s ďalšími skorumpovanými režimami univerzálnosť ľudských práv popierajú. Komunistická strana tvrdí, že ľudské práva sú vnútornou záležitosťou každého štátu. Toto tvrdenie používa ako výhovorku pre kolosálny útlak náboženskej slobody, národnostných menšín a disidentov. ČKS dokonca samu seba oslavuje ako ochrancu ľudských práv, ktorý „vytiahol stovky miliónov občanov ČĽR z chudoby“. Prisvojuje si tak zásluhy za tvrdú prácu a vynaliezavosť Číňanov.</p>
        <p>ČKS tiež používa OSN ako platformu pre útoky na demokratické hodnoty Západu a spolieha na svoje spojenectvo s rozvojovými krajinami, aby podkopával snahy slobodných národov o presadzovanie univerzálnych hodnôt. Kvôli manipulácii zo strany komunistických faktorov toho OSN nielenže príliš neurobila ohľadom zlepšenia ľudských práv, ale naopak často slúži komunistickým režimom ako nástroj na kamuflovanie biedneho stavu ľudských práv v týchto krajinách. Dore Gold, bývalý stály veľvyslanec Izraela pre OSN a autor knihy <em>Babylonská veža: Ako OSN rozdúchava globálny chaos</em> (Tower of Babble: How the United Nations Has Fueled Global Chaos), napísal: „OSN nie je neškodná, ale neúčinná svetová organizácia. Urýchľuje a šíri chaos v celom svete.“ Gold uvádza početné dôkazy, ktoré to potvrdzujú, vrátane „hodnotovej neutrality“ OSN a&nbsp;nemorálnosti „mravnej rovnocennosti“ a „mravného relativizmu“. Tiež uvádza všeobecnú korupciu v organizácii, skutočnosť, že nedemokratické štáty majú väčšinový hlas a že komunistickým režimom patrí neprimeraná kontrola.<a href="#_edn13" id="_ednref13">[13]</a> Gold píše, že OSN je „žalostným zlyhaním“, ktoré je „ovládané protizápadnými silami, diktátorskými režimami, štátnymi podporovateľmi terorizmu a&nbsp;najväčšími nepriateľmi Ameriky“.<a href="#_edn14" id="_ednref14">[14]</a></p>
        <p>Napríklad členmi Rady pre ľudské práva sa môžu stať aj krajiny, ktoré nedodržiavajú ľudské práva, čo absolútne znevažuje ňou vydávané dokumenty. Okrem toho si čínsky režim kúpil mnoho rozvojových krajín. To viedlo k tomu, že kritika Číny v&nbsp;otázke ľudských práv sa opakovane odkladá do šuplíka. Tyrania väčšiny, ktorú OSN umožňuje, sa stala nástrojom pre komunistické sily, ako v mnohých otázkach blokovať snahy slobodných národov o&nbsp;podporu slobody ľudských práv. To viedlo k tomu, že Spojené štáty z Rady pre ľudské práva vystúpili. Rady pre ľudské práva sa zmocnili autoritatívne štáty a takzvané medzinárodné dohody, ktoré OSN prijala, nijako neprispeli k obmedzeniu totalitných krajín, ktoré iba verklíkujú slogany, no v praxi ich neuplatňujú.</p>
        <p>Nie je teda ťažké pochopiť, prečo sa Charta OSN tak podobá na sovietsku ústavu a&nbsp;je v priamom rozpore s ústavou americkou. Jej účelom nie je ochraňovať práva ľudí, ale slúžiť potrebám politických vládcov. Sovietska ústava tiež na povrchu poskytovala občanom nejaké práva, ale v skutočnosti mnoho konkrétnych zákonov obsahovalo formuláciu typu „v medziach zákona“, čo sovietskemu režimu umožnilo svojvoľne odopierať občanom ich práva na základe výkladu „v medziach zákona“. Rovnako je to tak s&nbsp;Chartou OSN a ďalšími odvodenými zmluvami a dohodami, ktoré definujú práva ľudí rovnako vágnym spôsobom. Napríklad v Medzinárodnom pakte o občianskych a politických právach sú k ustanoveniam ako „každý má právo“ pripojené podmienky typu „vyššie spomenuté práva nepodliehajú žiadnym obmedzeniam okrem tých, ktoré ustanovuje zákon“. Nejde tu len o náhodnú voľbu textu, ale o „zadné dvierka“, ktoré si tam komunistické sily zámerne zabudovali.</p>
        <p>Ako napísal Edward Griffin: „V skutočnosti môže byť každé jedno právo vymenované v dohode OSN o ľudských právach zákonne odopreté, ak politici usúdia, že je to potrebné pre ochranu národnej bezpečnosti, verejného poriadku, verejného zdravia alebo morálky alebo práv a slobôd druhých‘. Väčšina vojen a&nbsp;národných zločinov je páchaná v mene jednej z týchto [zákonných podmienok].“<a href="#_edn15" id="_ednref15">[15]</a> Pre slobodné štáty nie je ľahké svojvoľne odoprieť občanom ich slobody, zato komunistické režimy môžu otvorene zneužiť medzery vo Všeobecnej deklarácii ľudských práv.</p>
        <h3>c) Propagácia politických myšlienok komunizmu vo svete</h3>
        <p>Duch komunizmu prostredníctvom svojich agentov opakovane vyvoláva globálne problémy a tvrdí, že tieto problémy možno vyriešiť iba medzinárodnou spoluprácou a&nbsp;globálnymi mocenskými štruktúrami. Jeho pravým cieľom je zriadiť svetovládu. V dôsledku toho sú štáty čím ďalej tým viac obmedzované a regulované neustále rastúcim počtom medzinárodných dohovorov. Výsledkom je oslabovanie národnej suverenity.</p>
        <p>Mnoho skupín takéto medzinárodné mocenské štruktúry podporuje, a hoci možno nejde o komunistov, ich tvrdenia zodpovedajú zámerom komunistických cieľov, t. j. eliminovať jednotlivé národy a nahradiť ich jednou svetovou vládou.</p>
        <p>V šestnástej kapitole sme detailne prebrali, ako komunizmus používa tvrdenie o ochrane životného prostredia na podporu svojej agendy. Environmentalizmus išiel ruka v ruke s úsilím o oslabenie národnej suverenity a&nbsp;podporou nadnárodnej politickej moci. Istá mediálna osobnosť vyhlásila v roku 1970 ku Dňu Zeme: „Ľudstvo potrebuje svetový poriadok. Plne suverénny národ nie je schopný vysporiadať sa s otravou životného prostredia. ... Správa planéty preto vyžaduje svetovú vládu, či už hovoríme o potrebe zabrániť vojne, alebo potrebe predchádzať konečnému zničeniu životných podmienok.“<a href="#_edn16" id="_ednref16">[16]</a></p>
        <p><em>Humanistický manifest II</em> z roku 1973 tiež vyhlásil: „V dejinách ľudstva sme dosiahli zlomový bod, keď najlepšou možnosťou je prekročiť limity národnej suverenity a vydať sa smerom k&nbsp;budovaniu svetového spoločenstva. ... Očakávame teda vývoj systému svetového práva a svetového poriadku založeného na nadnárodnej federálnej vláde.“<a href="#_edn17" id="_ednref17">[17]</a></p>
        <p>K založeniu Programu OSN pre životné prostredie došlo v&nbsp;skutočnosti práve kvôli tomu, že skupina volajúca v roku 1972 po globálnej konfederácii považovala ekologické otázky za svetový problém, a preto lobovala za globálne riešenie a založenie medzinárodnej organizácie pre ochranu prírody. Prvým riaditeľom bol Maurice Strong, Kanaďan so silnou inklináciou k socializmu.</p>
        <p>Na Summite Zeme v Rio de Janeiro v roku 1992 (tiež známom ako konferencia OSN o životnom prostredí a rozvoji) hlasovalo 178 vlád za prijatie „Agendy 21“<em>.</em> Tento osemsto stránkový dokument rozoberá rôzne témy, od životného prostredia cez práva žien až po lekársku starostlivosť. Vplyvná vedkyňa pracujúca v&nbsp;Programe OSN pre životné prostredie napísala: „Národná suverenita, teda schopnosť krajiny kontrolovať udalosti na svojom území, už v&nbsp;dnešnom svete stratila význam, pretože hranice sú bežne narušované znečistením, medzinárodným obchodom, finančnými tokmi a utečencami. ... Národy prakticky prenechávajú časť svojej suverenity medzinárodnej komunite a začínajú vytvárať nový systém medzinárodnej environmentálnej správy ako spôsob riešenia inak nezvládnuteľných problémov.“<a href="#_edn18" id="_ednref18">[18]</a></p>
        <p>Na povrchu sa zdajú tieto zdôvodnenia pre svetovládu skvelé, ale ich pravým účelom je propagácia komunizmu a svetová nadvláda. Komunistické režimy často verejne odsudzujú zasahovanie do vnútorných záležitostí iných štátov, ale pritom sú aktívne zapojené v&nbsp;najrôznejších medzinárodných organizáciách, kde presadzujú koncept globálnej vlády.</p>
        <p>Butrus Butrus-Gháli za svojho predsedníctva v&nbsp;rokoch 1992 až 1996 inicioval rýchly postup OSN k svetovej vláde. Tajomník sa zasadzoval za vytvorenie stáleho vojska OSN a tlačil na právo vyberať dane.<a href="#_edn19" id="_ednref19">[19]</a> Bolo to vďaka opozícii USA, že sa mu nepodarilo zotrvať na poste v druhom funkčnom období. Nebyť tohto zásahu, kto vie, akú moc by dnes OSN mala.</p>
        <p>Bývalý generálny tajomník OSN Kofi Annan v roku 2002 vyhlásil: „Vo veku vzájomnej previazanosti je svetové občianstvo rozhodujúcim pilierom pokroku.“ Robert Chandler, strategický analytik, ktorý pracoval pre letectvo Spojených štátov a Biely dom, sa domnieva, že Annanov takzvaný pokrok by zničil národnú suverenitu jednotlivých krajín a otvoril by cestu pre globálnu občiansku spoločnosť bez hraníc pod nadvládou „masívnej, odosobnenej medzinárodnej byrokracie, ktorá ... zníži jednotlivca na úroveň mravčích robotníkov v autoritatívnom vesmíre“. Program OSN s názvom „Vzdelávanie ku kultúre mieru“ je v skutočnosti organizovaný a vedený ultraľavičiarmi, o ktorých sa Chandler domnieva, že majú v úmysle zničiť suverenitu národov.<a href="#_edn20" id="_ednref20">[20]</a></p>
        <p>Kniha <em>Nahý komunista</em> z roku 1958 vymenováva 45 cieľov komunizmu, z ktorých jeden znie: „Propagujte OSN ako jedinú nádej pre ľudstvo. Pokiaľ sa bude prepisovať jej charta, vyžadujte, aby OSN fungovala ako svetovláda s nezávislými ozbrojenými silami.“<a href="#_edn21" id="_ednref21">[21]</a>&nbsp; Zriadenie svetovej vlády nie je možné dosiahnuť v&nbsp;krátkodobom horizonte, preto komunisti a globalisti využívajú najrôznejšie problémy v rozličných oblastiach na založenie medzinárodných inštitúcií. Potom propagujú jednotu týchto inštitúcií a ďalej lobujú za závislosť na OSN s konečným cieľom dosiahnuť svetovládu.</p>
        <h3>d) Svetová vláda a&nbsp;totalita</h3>
        <p>Na predstavovaní si lepšieho sveta či budúcnosti nie je nič zlé, ale snažiť sa o zavedenie svetovlády, ktorá má vyriešiť všetky problémy ľudstva, sa v ničom nelíši od scestnej „diktatúry proletariátu“ alebo centrálneho plánovania, ktoré tak presadzuje komunizmus.</p>
        <p>Obhajovanie svetovlády, posilňovanie úlohy OSN, vykresľovanie OSN ako všelieku pre vyriešenie všetkých problémov dnešného sveta, to všetko je súčasťou snáh hrať sa na Boha a určovať budúcnosť ľudstva. Keby k nastoleniu takéhoto režimu skutočne došlo, nevyhnutne by upadol do totality komunistického štýlu.</p>
        <p>Aby svetová vláda prilákala jednotlivé štáty k účasti, ponúkne im lákavé výhody, nasľubuje blahobyt pre ľudstvo a plán globálnej utópie. K tomu, aby mohla svetová vláda naozaj riešiť globálne problémy, musí zvoliť nejakú stratégiu, či už politickú, vojenskú, ekonomickú či inú. K presadeniu takýchto krokov v&nbsp;celosvetovom meradle by si svetová vláda musela zvoliť určitú formu vlády, a celkom iste by to nebola slobodná demokracia, akú poznáme v USA, ale silná totalitná vláda, akou bola tá v bývalom Sovietskom zväze alebo akou je dnešný komunistický režim v Číne. Táto centralizácia by pozdvihla štátnu moc na bezprecedentnú úroveň a adekvátne s tým by stúpla aj štátna kontrola nad spoločnosťou. V tejto fáze by sa takáto svetovláda už nestarala o to, či je medzi všetkými štátmi dosiahnutá zhoda a nebrala by do úvahy záväzky voči jednotlivým krajinám. Namiesto toho by sa zamerala len na rázne zavedenie svojich opatrení.</p>
        <p>V dnešnom svete&nbsp; existujú medzi jednotlivými krajinami veľké rozdiely. Je rad krajín, ktoré nemajú ani ortodoxnú vieru, ani slobodu, nehovoriac o&nbsp;úcte k ľudským právam&nbsp; alebo vysokým morálnym meradlám. Keď sa štáty spoja, aby utvorili jednu svetovládu, taká vláda prevezme najnižšie dané štandardy a odmietne akékoľvek požiadavky vzťahujúce sa k viere, morálke a ľudským právam. Inými slovami, za účelom zjednotenia dostanú krajiny v týchto otázkach voľnú ruku s odvolaním sa na koncept takzvanej neutrality v náboženstve, morálke a ľudských právach. Aby svetovláda zjednotila svet, bude nevyhnutne presadzovať kultúru hlavného prúdu, hoci každý štát má svoje vlastné kultúrne tradície a náboženské presvedčenie.</p>
        <p>Zo všetkých tých odborníkov, intelektuálov a vlád, ktorí aktívne obhajujú myšlienku svetovlády, je väčšina ateistov alebo zástancov progresívnych názorov na náboženskú vieru. Je očividné, že svetovláda by mala ateizmus ako svoju kľúčovú hodnotu. Ide o neodvratný dôsledok, keďže hnacou silou za konceptom svetovlády je komunizmus. Navyše, pretože medzi národmi panujú kultúrne a lingvistické rozdiely, ktoré by komplikovali globálnu autoritu, zástancovia svetovlády sa často stavajú proti konceptom ako je patriotizmus alebo uprednostňovanie lokálnej kultúry, ktoré sú nevyhnutné pre blaho národných štátov. Vláda krajiny alebo regiónu, kde ľudia nemajú spoločnú vieru a kultúru, sa môže udržať pri moci len pomocou totalitnej vlády. Výsledkom by bolo obmedzenie osobnej slobody občanov.</p>
        <p>Aby si svetovláda udržala moc, musela by násilne zavádzať ideologickú prevýchovu a uchyľovať sa k násiliu. Aby u členských štátov zabránila roztrieštenosti a kampaniam za nezávislosť, musela by svetovláda značne posilniť svoju vojenskú a policajnú moc a&nbsp;sprísniť kontrolu nad slobodou prejavu a nad médiami.</p>
        <p>Svetová vláda by teda nevyhnutne musela byť vládou totalitnou. Výsledok by nebol v ničom odlišný od komunistických režimov dneška, ktoré zotročujú a degradujú vlastný ľud. Jediným rozdielom by bolo iba to, že totalitná vláda by sa nevzťahovala na jedinú krajinu, ale na celú zemeguľu. Komunistický duch by zostal prakticky bez opozície a mohol by realizovať svoje plány na zničenie tradičných kultúr, vyhladenie duchovnej viery a konečné zničenie samotného ľudstva.</p>
        <h2>4. Kultúrna globalizácia: spôsob, ako pokaziť ľudstvo</h2>
        <p>Ako sa vo svete rozširuje kultúrna výmena a tok kapitálu, po zemeguli sa šíria aj rôzne pokrivené kultúrne formy, ktoré komunizmus vytvoril v posledných približne sto rokoch. &nbsp;Napríklad moderné umenie, literatúra a myšlienky, dekadentný životný štýl a&nbsp;konzumný spôsob života. Kultúrne tradície rôznych etnických skupín sú v tomto procese prerušené a odtrhnuté od svojho pôvodného významu, čo má za následok prázdny, zdegenerovaný životný štýl zameraný na spotrebu a&nbsp;zisk. Zároveň sa ním pokazili morálne hodnoty ľudí všade kam sa tieto pokrivené kultúrne formy rozšírili.</p>
        <p>Nemecký komunistický aktivista Willi Münzenberg, ktorý bol jedným zo zakladateľov frankfurtskej školy, povedal: „Musíme organizovať intelektuálov a s ich pomocou rozložiť západnú civilizáciu. Jedine vtedy, keď sa zničia všetky jej hodnoty a život sa spraví neznesiteľným, môžeme zaviesť diktatúru proletariátu.“<a href="#_edn22" id="_ednref22">[22]</a></p>
        <p>Ako sme popísali v predchádzajúcich kapitolách tejto knihy, dedičstvo západnej civilizácie bolo nahradené pokrivenou modernou pop kultúrou. Globalizácia a globalizmus prinášajú túto skazenosť do všetkých kútov sveta.</p>
        <p>Celosvetovo sú politickým, ekonomickým aj vojenským lídrom Spojené štáty. Toto vodcovstvo sa prenáša aj na americkú kultúru, ktorá je ochotne prijímaná a&nbsp;uznávaná ostatnými krajinami a regiónmi. Po infiltrácii a narušení rodiny, politiky, ekonómie, práva, umenia, médií a ľudovej kultúry naprieč všetkými aspektmi denného života v Spojených štátoch, a po skazení a&nbsp;zničení tradičných morálnych hodnôt využil komunizmus kultúrnu globalizáciu k vývozu tejto skazenej kultúry. Táto kultúra, ospevovaná ako pokročilá kultúra z USA, sa rozšírila do celého sveta. Vďaka hollywoodskym filmom sa ženy v konzervatívnych dedinkách odľahlých regiónov Číny dozvedajú, že slobodné matky, mimomanželské vzťahy a sexuálna sloboda sú všetko „normálne“ aspekty života na „pokrokovom“ Západe. Od Ekvádoru v&nbsp;Južnej Amerike cez Malajziu v juhovýchodnej Ázii až po Fidži v Tichomorí, všade sa Rock ‘n’ roll stal nesmierne populárnym. Ideológia v&nbsp; školstve, ktorá je základom školských osnov pre stredné školy, vytvorená kultúrnymi marxistami, sa takmer okamžite prejavila v stredoškolských učebniciach na Tchai-wane. V&nbsp;krátkom čase sa správy o newyorskom hnutí Occupy Wall Street objavili na televíznych obrazovkách v najodľahlejších horských dedinách v Indii.</p>
        <p>Kultúrna globalizácia je hurikán, ktorý vháňa skazenú kultúru Západu a stranícku kultúru totalitných komunistických režimov do celého sveta, pričom nemilosrdne zametá preč tradičné hodnoty, ktoré viedli ľudstvo po tisíce rokov.</p>
        <h3>a) Ničenie svetových kultúrnych tradícií</h3>
        <p>Kultúra každej národnosti vo svete má jedinečné charakteristiky a nesie v sebe hlboké vplyvy z&nbsp;jej vlastnej histórie. Napriek rozdielom medzi kultúrami jednotlivých národností sa tradícia všetkých týchto kultúr riadi rovnakými božsky ustanovenými univerzálnymi hodnotami. Technologický rozvoj po priemyselnej revolúcii priniesol do životov väčšie pohodlie a tradície sa pod vplyvom pokroku označujú za zaostalé. Teraz je štandardom merať všetko podľa toho, či je to moderné, nové a „pokrokové“, alebo podľa toho, či to má komerčnú hodnotu.</p>
        <p>Komunizmus propaguje hodnoty, ktoré sú zdanlivo ušľachtilé, ale v skutočnosti je ich cieľom, aby ľudstvo opustilo tradičné hodnoty a nahradilo ich homogénnymi a pokrivenými modernými hodnotami. Dnešné takzvané spoločné hodnoty utvorené kultúrnou výmenou v procese globalizácie už nepatria k žiadnej konkrétnej tradícii: sú to moderné hodnoty. Prvky a hodnoty, ktoré si globalizácia osvojuje, sa odchyľujú od tradícií. Zahŕňajú len tie najpovrchnejšie prvky existujúceho kultúrneho dedičstva a stránky, ktoré možno komercializovať. Názory o „spoločnom osude ľudstva“ a „našej spoločnej budúcnosti“ sú výsledkom práve takýchto odchýlených hodnôt.</p>
        <p>Najnižší štandard, ktorý sa v rámci kultúrnej globalizácie celosvetovo uznáva, sa prejavuje tiež v spotrebiteľskej kultúre. Dizajn výrobkov a to ako sa propagujú, sú v honbe za ekonomickými záujmami stavané tak, aby priťahovali prízemné inštinkty spotrebiteľov. Cieľom je ovládať ľudstvo uspokojovaním jeho povrchných túžob.</p>
        <p>Globálna spotrebiteľská kultúra sa využíva k rozbitiu tradícií z viacerých uhlov. Po prvé, z výrobkov sú odstraňované ich jedinečné rysy a vnútorné významy danej národnosti. Inými slovami, z výrobkov sa prostredníctvom dekulturalizácie alebo štandardizácie odstraňujú tradičné prvky. Čím viac je skupina ľudí odcudzená od svojho kultúrneho dedičstva a viery, tým ľahšie prepadá takejto zjednodušenej konzumnej kultúre. Časom pôsobením globalizácie poklesnú zvyky a identita tejto skupiny obyvateľov na nízku úroveň, ktorá je potrebná len na zachovanie lacnej komerčnej kultúry bez hlbších významov a mravných zásad.</p>
        <p>Po druhé, globalizácia mediálneho priemyslu a jeho monopoly umožnili komunistickým prvkom ľahko využívať degenerované myšlienky za produktami. Propagujú povrchné kultúrne aspekty výrobkov a pritom podsúvajú marxistickú ideológiu. Hybridizácia kultúr prostredníctvom globalizácie sa tak stáva ďalším kanálom pre propagáciu komunistickej ideológie.</p>
        <p>Po tretie, globálna kultúra robí z konzumu hlavný kultúrny prúd spoločnosti. Reklamy, filmy, televízne show a sociálne médiá neustále bombardujú spotrebiteľa myšlienkou, že ak nekonzumujú či nevlastnia určitý výrobok alebo sa nenechávajú určitým spôsobom baviť, potom nežijú skutočný život. Komunizmus využíva rôzne prostriedky a spôsoby zábavy, aby ľudí podnietil k&nbsp;uspokojovaniu svojich túžob. Ako sa ľudia týmto túžbam oddávajú, vzďaľujú sa od duchovnej sféry, a&nbsp;v&nbsp;priebehu niekoľkých generácií sa odklonia od odvekej viery v Boha a od tradičných hodnôt.</p>
        <p>Ako komunizmus rýchlo šíri svoju ideológiu na pozadí globalizácie, využíva tiež mentalitu stáda. Ľudia sú často vystavovaní sociálnym médiám, reklamám, televíznym reláciám, filmom a správam, kde sa vyskytujú najrôznejšie antitradičné ideológie. To vytvára ilúziu, že takéto odchýlené ideológie predstavujú globálny konsenzus. Ľudia sa postupne stávajú otupenými voči škode, ktorú tieto ideológie páchajú. Pokrivené správanie sa považuje za módne a ľudia sú povzbudzovaní k tomu, aby naň boli hrdí. Konzumácia návykových látok, sexuálna sloboda, pokrivená hudba, abstraktné umenie a mnohé ďalšie, to všetko túto módnu vlnu šíri.</p>
        <p>Moderné umenie je zdegenerované a porušuje všetky tradičné definície estetiky. Niektorí jednotlivci si to možno zo začiatku uvedomili, ale keď sú moderné diela neustále vystavované na prestížnych miestach a predávajú sa za vysoké ceny, a keď médiá neustále informujú o temných a podivných umeleckých výtvoroch, ľudia začnú veriť tomu, že oni sú tí, ktorí nedržia krok s dobou, a&nbsp;že si musia pozmeniť svoj vkus. A tak ľudia začínajú potláčať vlastné vnímanie toho, čo je krásne, aby dávali prednosť skazeným umeleckým formám.</p>
        <p>Do každého kúta sveta sa momentálne šíria všemožné podoby pokazenej kultúry zamaskovanej ako západná kultúra. Obzvlášť Hollywood sa stal hlavným nositeľom najrôznejších ideológií prameniacich z kultúrneho marxizmu. Špecifické rysy filmového priemyslu umožňujú, aby ľudia podvedome prijímali jeho hodnoty. V&nbsp;trinástej kapitole tejto knihy sme popísali, že film má ohromnú moc. Dokáže vyobraziť podmanivú atmosféru, príbehy a&nbsp;osobnosti a vtiahnuť diváka do pohľadu režiséra. Filmy z Hollywoodu hrajú pri tvarovaní hodnôt a svetonázoru divákov veľkú úlohu.</p>
        <p>V tejto knihe sme tiež popísali, ako kultúrny marxizmus pohltil západné školstvo (pozri dvanástu kapitolu) a&nbsp;vystavuje zahraničných študentov rôznym ľavicovým ideológiám. Keď sa títo študenti potom vrátia do svojich rodných krajín, sami sa stanú šíriteľmi týchto ideológií. V ich krajinách sú tieto ideológie považované za atraktívne, pretože na západné štáty sa nazerá ako na technologicky zdatné a ekonomicky rozvinuté, a&nbsp;teda tieto ideológie nenarážajú na väčší odpor, pričom ničia miestnu tradičnú kultúru.</p>
        <p>Tieto moderné globalistické hodnoty sa dnes stali všadeprítomnými a mainstreamovými, kvôli korporátnej kultúre panujúcej v nadnárodných spoločnostiach. Najmä propagácia sexuálneho oslobodenia sa pôsobením globalizácie rozšírila veľmi rýchlo a&nbsp;vážne prispela k narušeniu morálnych hodnôt tradičnej spoločnosti.</p>
        <p>Istý veľký nadnárodný reťazec oznámil roku 2016, že toalety a šatne v jeho obchodoch budú brať ohľad na transgender osoby, čo znamená, že akýkoľvek muž môže vstúpiť na toalety pre ženy alebo do ženských šatní za predpokladu, že o sebe bude vyhlasovať, že je žena. Americký zväz rodín vyzval spotrebiteľov, aby túto firmu bojkotovali, pretože jej interné predpisy by mohli škodiť ženám a deťom. K&nbsp;dátumu písania tejto knihy sa k bojkotu predajného reťazca pridalo prostredníctvom podpisov cez 1,5 milióna ľudí.<a href="#_edn23" id="_ednref23">[23]</a> Lenže bojkoty tohto druhu sú už nereálne, pretože podobné opatrenia postupne zavádzajú firmy naprieč každým odvetvím. Komunizmu sa darí využívať mentalitu stáda vďaka tomu, že mnoho ľudí nemá dostatočne silnú vôľu. Hneď ako sa ľudstvo odchýli od božsky ustanovených tradícií, všetko sa stane relatívnym a časom sa to zmení. Táto situácia je ako stvorená pre zneužitie.</p>
        <p>Podľa predpokladov globalizácie sa vzájomná úcta a&nbsp;tolerancia k rôznym národným kultúram stala hlavným názorovým prúdom. Komunizmus to využil, aby skreslil koncept tolerancie a urobil z hodnotovej neutrality „globálnu zhodu“, čím obhajuje odchýlené myšlienky.</p>
        <h3>b) Úloha OSN pri šírení pokrivených hodnôt</h3>
        <p>Článok 13 Dohovoru OSN o právach dieťaťa uvádza: „Dieťa má právo na slobodu prejavu: toto právo zahŕňa vyhľadávať, prijímať a rozširovať informácie a myšlienky každého druhu, bez ohľadu na hranice, či už ústne, písomne ​​alebo tlačou, prostredníctvom umenia alebo akýmikoľvek inými prostriedkami podľa voľby dieťaťa.“<a href="#_edn24" id="_ednref24">[24]</a></p>
        <p>Niektorí odborníci sa pýtali, či to, že rodičia nedovolia svojim deťom, aby nosili tričká so satanistickými symbolmi, neznamená porušenie detských práv. Majú deti právo zvoliť si, ako sa budú rozprávať so svojimi rodičmi? Deti môžu mať mylný úsudok. Pokiaľ spáchajú zločin alebo porušia etické normy, môžu rodičia svoje deti potrestať? Tieto obavy nie sú neopodstatnené. Kanadské Ontario schválilo v roku 2017 zákon o tom, že rodičia by nemali svojim deťom odopierať právo zvoliť si vlastné pohlavie. Rodičia, ktorí neprijmú novozvolenú pohlavnú identitu svojho potomka, môžu byť obvinení z týrania detí a štát im ich môže odobrať.<a href="#_edn25" id="_ednref25">[25]</a></p>
        <p>Svetová zdravotnícka organizácia oznámila v roku 1990, že homosexualita nie je duševná choroba, čo značne povzbudilo hnutia LGBT na celom svete. JAR bola prvou krajinou, ktorá predstavila v Rade OSN pre ľudské práva nové pravidlo, ktoré by požadovalo, aby sa uznanie sexuálnej orientácie a pohlavnej identity používalo ako indikátor ľudských práv. Dohovor bol nakoniec prijatý. V skutočnosti ide o normalizáciu vecí, ktoré sa predtým považovali za odchýlené myšlienky, a priraďuje sa im rovnaká dôležitosť ako prirodzeným právam.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <p>Komunizmus používa globalizáciu, aby celoplošne skreslil a zničil tradičnú kultúru a morálne hodnoty. Využíva k tomu rozvinuté krajiny, nadnárodné korporácie a medzinárodné orgány. Ľudia sú ponorení do povrchného pohodlia, ktoré globálny život prináša, ale netušia, že sa im rapídne mení myslenie a vedomie. Tieto úplne nové myšlienky pohltili za posledných niekoľko desaťročí značnú časť sveta ako zúrivé tsunami. Všade tam, kam tieto myšlienky prídu, sa mení kultúra. Uniknúť tomu nedokážu ani tie najstaršie a najuzavretejšie štáty. Ak bude tento trend pokračovať, povedie to k zničeniu samotnej civilizácie.</p>
        <p>Tradičná kultúra je koreňom ľudskej existencie a dôležitou zárukou toho, že si ľudské bytosti zachovajú morálne štandardy. Je to kľúč k tomu, aby mohli byť ľudské bytosti zachránené svojím Stvoriteľom. V procese globalizácie bola tradičná kultúra pokrivená či dokonca zničená usporiadaniami komunistického ducha. Dôsledkom je, že ľudská civilizácia čelí dlhoročnej bezprecedentnej kríze.</p>
        <h2>5. Udržiavanie národného dedičstva a univerzálnych hodnôt</h2>
        <p>Rôzne národy a krajiny existujú už tisícročia. Hoci existujú v rozdielnych geografických oblastiach, majú rozdielne formy spoločnosti a politické systémy, a aj keď používajú odlišné jazyky a majú odlišné kultúrne a&nbsp;psychologické charakteristiky, napriek tomu všetky zdieľajú spoločné univerzálne hodnoty. Tieto hodnoty sú základom tradičnej kultúry pre všetky etnické skupiny.</p>
        <p>Najmä po skončení studenej vojny začali predstavitelia komunizmu na východe aj na západe používať medzinárodnú politickú, ekonomickú a&nbsp;kultúrnu výmenu a spoluprácu na rozšírenie globalizácie po celom svete. Globalistické inštitúcie presadzujú pokrivené hodnoty prakticky v každom štáte na zemeguli a pomáhajú duchovi komunizmu pri systematickom ničení univerzálnych hodnôt, tradičnej kultúry a duchovnej viery.</p>
        <p>Tým, že duch komunizmu použil v posledných niekoľkých storočiach globalizmus spolu s ďalšími dejinnými procesmi, značne si rozšíril svoju moc v ľudskom svete a priniesol veľké škody tradičnej kultúre ľudstva inšpirovanej nebesami.</p>
        <p>V prvej polovici 20. storočia sa komunisti ujali moci v&nbsp;Rusku a následne v Číne, kde vo veľkom vyvraždili kultúrne elity oboch národov a zničili tradičnú kultúru. Po druhej svetovej vojne sa komunistické štáty infiltrovali do medzinárodných organizácií ako OSN a získali nad ňou kontrolu. Tieto krajiny zneužili demokratické procesy na to, aby väčšina zvíťazila nad menšinou a peniazmi si kúpili malé štáty v snahe zneužiť nadnárodnú moc OSN na&nbsp;šírenie svojho politického systému vo svete.</p>
        <p>Za posledných viac ako sto rokov od objavenia sa komunizmu na medzinárodnom javisku &nbsp;získali tieto nadnárodné politické a ekonomické sily obrovskú moc a ohrozujú všetky suverénne štáty svojou agendou svetovej vlády.</p>
        <p>Jedine návratom k tradíciám môžu ľudské bytosti znovu obnoviť svoju národnú identitu a suverenitu a vytvoriť harmonické medzinárodné prostredie, kde sa všetci budú riadiť čestnými univerzálnymi hodnotami. To umožní ľudstvu odstrániť ducha komunizmu a žiť pod ochranou Boha a v jeho milosti.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><a href="#_ednref1" id="_edn1">[1]</a> Karol Marx a Friedrich Engels, „The German ideológ“, <em>Marx-Engels Collected Works,</em> sv. 5 (1932), pozreté 4. mája 2020 na Marxists Internet Archive, <a href="https://www.marxists.org/archive/marx/works/1845/german-ideology">https://www.marxists.org/archive/marx/works/1845/german-ideology</a> / index.htm</p>
        <p><a href="#_ednref2" id="_edn2">[2]</a> V. I. Lenin, „The Third, Communist International“ ,<em>Lenin's Collected Works</em>, 4. anglické vydanie, zväzok 29 (Moscow: Progress Publishers, 1972), 240-241, <a href="https://www.marxists.org/archive/lenin/works/1919/mar/x04.htm.">https://www.marxists.org/archive/lenin/works/1919/mar/x04.htm.</a></p>
        <p><a href="#_ednref3" id="_edn3">[3]</a> G. Edward Griffin, Fearful Master: A Second Look at the United Nations (Appleton, WI: Western Islands, 1964), kapitola 7.</p>
        <p><a href="#_ednref4" id="_edn4">[4]</a> William Z. Foster, <em>Toward Soviet America</em> (New York: Coward-McCann, 1932), kapitola 5, kroky 4. mája 2020 na Marxists Internet Archive, <a href="https://www.marxists.org/archive/foster/1932/Toward">https://www.marxists.org/archive/foster/1932/Toward</a> / 06.htm.</p>
        <p><a href="#_ednref5" id="_edn5">[5]</a> James Bovard, „The World Bank vs. the World's Poor“, <em>Cato Institute Policy Analysis No.</em> <em>92</em>, 28. septembra 1987, <a href="https://object.cato.org/sites/cato.org">https://object.cato.org/sites/cato.org</a> / files / pubs / pdf / pa092.pdf.</p>
        <p><a href="#_ednref6" id="_edn6">[6]</a> Dani Rodrik, <em>The Globalization Paradox: Why Global Markets, States, and Democracy Can not Coexist </em>(New York: Oxford University Press, 2011), 19.</p>
        <p><a href="#_ednref7" id="_edn7">[7]</a> Robert Atkinson, „Why the 2000s Were a Lost Decade for American Manufacturing “, <em>IndustryWeek,</em> 14. marca 2013,</p>
        <p>https: // www.industryweek.com/the-economy/article/22006840/why-the-2000swere-a-lost-decade-for-american-manufacturing.</p>
        <p><a href="#_ednref8" id="_edn8">[8]</a> U.S. Bureau of Labor Statistics, „A Profile of the Working Poor, 2016 “, BLS Reports, júl 2018, <a href="https://www.bls.gov/opub/reports/working-poor">https://www.bls.gov/opub/reports/working-poor</a> / 2016 / home.htm.</p>
        <p><a href="#_ednref9" id="_edn9">[9]</a> William F. Jasper, <em>Global Tyranny ... Step by Step: The United Nations and the Emerging New World Order </em>(Appleton, WI .: Western Islands Publishers, 1992), kap. 4.</p>
        <p><a href="#_ednref10" id="_edn10">[10]</a> J. Edgar Hoover, citácie podľa: Griffin, <em>Fearful Master</em>, 48.</p>
        <p><a href="#_ednref11" id="_edn11">[11]</a> Amity Shlaes, „Communism Becomes Cronyism at the UN,“ <em>The Wall Street Journal</em>, 24. októbra 1991.</p>
        <p><a href="#_ednref12" id="_edn12">[12]</a> Colum Lynch, „China Enlists U.N. to Promote Its Belt and Road Project“, <em>Foreign Policy</em>, 10. mája 2018, https: // foreignpolicy.com / 2018/05/10 / china-enlists-u-n-to-promote-its-belt-and-roadproject /.</p>
        <p><a href="#_ednref13" id="_edn13">[13]</a> Dore Gold, Tower of Babble: How the United Nations Has Fueled Global Chaos (New York, Crown Forum, 2004), 1-24.</p>
        <p><a href="#_ednref14" id="_edn14">[14]</a> Dore Gold, citácie podľa: Robert Chandler, <em>Shadow World: Resurgent Russia, The Global New Left, and Radical Islam</em> (Washington, DC: Regnery Publishing, 2008), 403.</p>
        <p><a href="#_ednref15" id="_edn15">[15]</a> Griffin, Fearful Master, kapitola 11.</p>
        <p><a href="#_ednref16" id="_edn16">[16]</a> Norman Cousins, citácie podľa: Gary Benoit, „Earth Day - The Greatest Sham on Earth“, <em>The New American</em>, 21. apríla 2016, https: // www.thenewamerican.com/tech/environment/item/23011-earth-day-the- greatest- sham-on-earth.</p>
        <p><a href="#_ednref17" id="_edn17">[17]</a> American Humanist Association, „Humanist Manifesto II“ (Washington, DC: American Humanist Association, 1973), <a href="https://americanhumanist.org/what-is-humanism/manifesto2">https://americanhumanist.org/what-is-humanism/manifesto2</a></p>
        <p><a href="#_ednref18" id="_edn18">[18]</a> Hilary F. French et al., „After the Earth Summit: The Future of Environmental Governance“, Worldwatch Institute 107, marec 1992.</p>
        <p><a href="#_ednref19" id="_edn19">[19]</a> Jasper, <em>Global Tyranny</em>, kap. 4.</p>
        <p><a href="#_ednref20" id="_edn20">[20]</a> Chandler, <em>Shadow World</em>, 401-403.</p>
        <p><a href="#_ednref21" id="_edn21">[21]</a> W. Cleon Skousen, <em>The Naked Communist</em> (Salt Lake City: Izzard Ink Publishing, 1958, 2014), kapitola 12.</p>
        <p><a href="#_ednref22" id="_edn22">[22]</a> Willi Münzenberg, citácie podľa: Bernard Connolly, <em>The Rotten Heart of Europe: Dirty War for Europe 's Money </em>(London: Faber &amp; Faber, 2013).</p>
        <p><a href="#_ednref23" id="_edn23">[23]</a> „Sign the Boycott Target Pledge!“, American Family Association, apríl 2016, <a href="https://www.afa.net/target.">https://www.afa.net/target.</a></p>
        <p><a href="#_ednref24" id="_edn24">[24]</a> United Nations Office of the High Commissioner for Human Rights, „Convention on the Rights of the Child“ (Geneva: United Nations, 1989), <a href="https://www.ohchr.org/en/professionalinterest/pages/crc.aspx.">https://www.ohchr.org/en/professionalinterest/pages/crc.aspx.</a></p>
        <p><a href="#_ednref25" id="_edn25">[25]</a> Grace Carr, „Ontario Makes Disapproval of Kid 's Gender Choice Potential Child Abuse “, <em>The Daily Caller,</em> 5. júna 2017, <a href="https://dailycaller.com/2017/06/05/ontario-makesdisapproval-of-kids-genderchoice-child-abuse/.">https://dailycaller.com/2017/06/05/ontario-makesdisapproval-of-kids-genderchoice-child-abuse/.</a></p>
      <Link to={`/kapitola_18`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


